<template>

<RadioButtonGroup 
  v-bind="{
      ...$props,
      value: newValue,
      label
    }"
    @change="updateValue"
/>
  
</template>

<script>
import RadioButtonGroup from '@/components/form/RadioButtonGroup.vue'

export default {
  name: 'EmailToRetailerRadioButtonGroup',
  components: {
    RadioButtonGroup
  },
  data() {
    return {
      newValue: null,
      label: null
    }
  },
  props: {
    rules: String,
    value: Boolean,
    options: Array,
    required: Boolean,
    errors: Array,
    small: Boolean,
    disabled: Boolean,
    module: String,
  },
  emits: [
    'change'
  ],
  methods: {
    updateValue(v) {
        this.newValue = v;
        this.$emit('change', this.newValue)       
    },
  },
  watch: {
    value: {
        immediate: true,
        handler(newVal) {
            this.newValue = newVal;
        }
    },
  },
  mounted() {
    const agent = this.$store.getters.getControlValue(this.module, 'Agent');
    this.label = 'Do you wish to send the Submission Confirmation email to the Retail Broker (' + (agent.Email || "").toLowerCase() + ')?';
  }
}

</script>
